import React, { useEffect, useState } from 'react';
import NavbarWrapper from './Layout';
import axios from 'axios';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import auth from '../config/FirebaseConfig';
import { useLocation } from 'react-router-dom';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { DetailsStdComponent } from '../utils/helpers/ComponentBuilder';
import { arrEl } from '../utils/helpers/commons';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const MovingServiceDetails: React.FC = () => {
  const location = useLocation();
  if (!location.state?.order) {
    throw new Error('This case should never happen!');
  }
  const [order, setOrder] = useState<any>(location.state.order);
  const [token, setToken] = useState<string>('');
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const userToken = await auth.currentUser?.getIdToken();
        const res = await axios.get(
          `${baseUrl}/admin/v1/orders/${location.state.order.id}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        );
        setOrder(res.data);
        setToken(userToken || '');
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    fetchOrder();
  }, [location.state.order.id]);
  console.log(order);
  return (
    <NavbarWrapper>
      <Accordion>
        <AccordionItem eventKey="0">
          <AccordionHeader>Order Information</AccordionHeader>
          <AccordionBody>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'seq',
                  () => {},
                  'Order Number',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'description',
                  () => {},
                  'Order Description',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'createdAt',
                  () => {},
                  'Created At',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'contactInfo',
                  () => {},
                  'Contact Info',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(order, 'status', () => {}, 'Status', true)}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'paymentMethod',
                  () => {},
                  'Payment Method',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(order, 'price', () => {}, 'Price', true)}
              </Col>
              <Col>
                {DetailsStdComponent(order, 'currency', () => {}, null, true)}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'paymentStatus',
                  () => {},
                  'Payment Status',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'updatedAt',
                  () => {},
                  'Updated At',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'assignee',
                  () => {},
                  'Assignee',
                  true,
                )}
              </Col>
            </Row>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey="1">
          <AccordionHeader>Location Details</AccordionHeader>
          <AccordionBody>
            <Card body> Pickup Details </Card>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'preferredPickupTimes',
                  () => {},
                  null,
                  true,
                )}
              </Col>
            </Row>
            <Card body className="mt-4">
              Pickup Location Details
            </Card>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'pickUpAddress',
                  () => {},
                  'Address',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}),
                  'residentDetails',
                  () => {},
                  'Name',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}),
                  'addressPhone',
                  () => {},
                  'Mobile',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}),
                  'addressDescription',
                  () => {},
                  'Description',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}).moveServiceLocationDetails ||
                    {},
                  'floor',
                  () => {},
                  'Floor',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}).moveServiceLocationDetails ||
                    {},
                  'apartment',
                  () => {},
                  'Apartment No.',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}).moveServiceLocationDetails ||
                    {},
                  'doorCode',
                  () => {},
                  'Door Code',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}).moveServiceLocationDetails ||
                    {},
                  'hasElevator',
                  () => {},
                  'Has Elevator',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}).moveServiceLocationDetails ||
                    {},
                  'elevatorFits',
                  () => {},
                  'ElevatorFits The content',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}).moveServiceLocationDetails ||
                    {},
                  'description',
                  () => {},
                  'Other Info',
                  true,
                )}
              </Col>
            </Row>
            <Card body className="mt-4">
              DropOff Location Details
            </Card>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'dropOffAddress',
                  () => {},
                  'Address',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}),
                  'residentDetails',
                  () => {},
                  'Name',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}),
                  'addressPhone',
                  () => {},
                  'Mobile',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}),
                  'addressDescription',
                  () => {},
                  'Description',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}).moveServiceLocationDetails ||
                    {},
                  'floor',
                  () => {},
                  'Floor',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}).moveServiceLocationDetails ||
                    {},
                  'apartment',
                  () => {},
                  'Apartment No.',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}).moveServiceLocationDetails ||
                    {},
                  'doorCode',
                  () => {},
                  'Door Code',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}).moveServiceLocationDetails ||
                    {},
                  'hasElevator',
                  () => {},
                  'Has Elevator',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}).moveServiceLocationDetails ||
                    {},
                  'elevatorFits',
                  () => {},
                  'ElevatorFits The content',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}).moveServiceLocationDetails ||
                    {},
                  'description',
                  () => {},
                  'Other Info',
                  true,
                )}
              </Col>
            </Row>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey="2">
          <AccordionHeader>Extra Details</AccordionHeader>
          <AccordionBody>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'promoCode',
                  () => {},
                  'Promo Code',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'trackingId',
                  () => {},
                  'Tracking Id',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'courierPhoneNumber',
                  () => {},
                  'Courier PhoneNumber',
                  true,
                )}
              </Col>
            </Row>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey="4">
          <AccordionHeader>Images</AccordionHeader>
          <AccordionBody>
            {DetailsStdComponent(order, 'files', () => {}, null, false, token)}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </NavbarWrapper>
  );
};

export default MovingServiceDetails;
