import CookieConsent from 'react-cookie-consent';

const CookieConsentComponent: React.FC = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="flieger-cookie-consent"
      buttonClasses="btn btn-primary m-2"
      overlay
      disableButtonStyles
      expires={365}
    >
      This website uses cookies. By using this website, you consent to the use
      of cookies.
    </CookieConsent>
  );
};

export default CookieConsentComponent;
