import { UTCDate } from '@date-fns/utc';
import { addMinutes } from 'date-fns';

export const PREFERRED_PICKUP_TIMES_PRECISION_IN_MINUTES = 30;

export const toUtcTz = (date: Date | UTCDate | string): UTCDate => {
  if (typeof date === 'string') {
    return new UTCDate(date);
  }
  return new UTCDate(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );
};

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatTime = (date: Date): string => {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const formatTimeRange = (date: Date, precisionInMin: number): string => {
  const dateRangeEnd = addMinutes(date, precisionInMin);
  const hoursStart = String(date.getHours()).padStart(2, '0');
  const hoursEnd = String(dateRangeEnd.getHours()).padStart(2, '0');
  const minutesStart = String(date.getMinutes()).padStart(2, '0');
  const minutesEnd = String(dateRangeEnd.getMinutes()).padStart(2, '0');
  return `${hoursStart}:${minutesStart}-${hoursEnd}:${minutesEnd}`;
};

export const formatDateTime = (date: Date): string => {
  return `${formatDate(date)} ${formatTime(date)}`;
};

export const arrEl = (arr: any[], idx: number, els: any): any => {
  return arr && arr.length >= idx + 1 ? arr[idx] : els;
};
