/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import NavbarWrapper from './Layout';
import axios from 'axios';
import { Button, Row, Col, FormControl, InputGroup } from 'react-bootstrap';
import auth from '../config/FirebaseConfig';

const configsMap = new Map<string, { key: string; title: string }>();
configsMap.set('largeVehicleOpenRate', {
  key: 'LARGE_VEHICLE_OPEN_RATE',
  title: 'Large Vehicle Open Rate',
});
configsMap.set('largeVehicleRatePerKm', {
  key: 'LARGE_VEHICLE_RATE_PER_KM',
  title: 'Large Vehicle Rate Per Km',
});
configsMap.set('largeVehicleWaitingRatePerMin', {
  key: 'LARGE_VEHICLE_WAITING_RATE_PER_MIN',
  title: 'Large Vehicle Waiting Rate Per Min',
});
configsMap.set('smallVehicleOpenRate', {
  key: 'SMALL_VEHICLE_OPEN_RATE',
  title: 'Small Vehicle Open Rate',
});
configsMap.set('smallVehicleRatePerKm', {
  key: 'SMALL_VEHICLE_RATE_PER_KM',
  title: 'Small Vehicle Rate Per Km',
});
configsMap.set('smallVehicleWaitingRatePerMin', {
  key: 'SMALL_VEHICLE_WAITING_RATE_PER_MIN',
  title: 'Small Vehicle Waiting Rate Per Min',
});
configsMap.set('smallManHourRate', {
  key: 'SMALL_MAN_HOUR_RATE',
  title: 'Small Man Hour Rate',
});
configsMap.set('largeManHourRate', {
  key: 'LARGE_MAN_HOUR_RATE',
  title: 'Large Man Hour Rate',
});
configsMap.set('largeVehicleDriverCost', {
  key: 'LARGE_VEHICLE_DRIVER_COST',
  title: 'Large Vehicle Driver Cost',
});
configsMap.set('smallVehicleDriverCost', {
  key: 'SMALL_VEHICLE_DRIVER_COST',
  title: 'Small Vehicle Driver Cost',
});
configsMap.set('smallDefVehicleWaitedMinutes', {
  key: 'SMALL_DEF_VEHICLE_WAITED_MINUTES',
  title: 'Small Default Vehicle Waited Minutes',
});
configsMap.set('largeDefVehicleWaitedMinutes', {
  key: 'LARGE_DEF_VEHICLE_WAITED_MINUTES',
  title: 'Large Default Vehicle Waited Minutes',
});
configsMap.set('smallDefManHoursWorked', {
  key: 'SMALL_DEF_MAN_HOURS_WORKED',
  title: 'Small Default Man Hours Worked',
});
configsMap.set('largeDefManHoursWorked', {
  key: 'LARGE_DEF_MAN_HOURS_WORKED',
  title: 'Large Default Man Hours Worked',
});
configsMap.set('packingDefHoursWorked', {
  key: 'PACKING_DEF_HOURS_WORKED',
  title: 'Packing Default Hours Worked',
});
configsMap.set('assemblyDefHoursWorked', {
  key: 'ASSEMBLY_DEF_HOURS_WORKED',
  title: 'Assembly Default Hours Worked',
});
configsMap.set('moveServiceCommissionPercent', {
  key: 'MOVE_SERVICE_COMMISSION_PERCENT',
  title: 'Move Service Commission Percent',
});
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const MovingPricingSettings: React.FC = () => {
  const [configs, setConfigs] = useState<any>({});
  const updateConfig = async (key: string, value: string) => {
    try {
      const userToken = await auth.currentUser?.getIdToken();
      await axios.patch(
        `${baseUrl}/admin/v1/config/update/${key}`,
        { value },
        { headers: { Authorization: `Bearer ${userToken}` } },
      );
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const userToken = await auth.currentUser?.getIdToken();
        const res = await axios.get(`${baseUrl}/customer/v1/settings`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setConfigs(res.data.movingServiceSettings);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    fetchSettings();
  }, []);
  return (
    <NavbarWrapper>
      {Array.from(configsMap.keys()).map((fieldName) => (
        <Row>
          <Col>
            <InputGroup className="p-lg-3">
              <InputGroup.Text id={fieldName}>
                {configsMap.get(fieldName)?.title}
              </InputGroup.Text>
              <FormControl
                type={
                  typeof configs[fieldName] === 'number' ? 'number' : 'text'
                }
                value={configs[fieldName]}
                onChange={(e: any) => {
                  const newConfig = { ...configs };
                  newConfig[fieldName] = e.target.value;
                  setConfigs(newConfig);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs>
            <Button
              variant="primary"
              onClick={() =>
                updateConfig(
                  configsMap.get(fieldName)?.key || '',
                  configs[fieldName] || '',
                )
              }
              className="m-lg-3"
            >
              Update
            </Button>
          </Col>
        </Row>
      ))}
    </NavbarWrapper>
  );
};

export default MovingPricingSettings;
