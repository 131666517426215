import React, { useEffect, useState } from 'react';
import NavbarWrapper from './Layout';
import axios from 'axios';
import {
  Button,
  Row,
  Col,
  InputGroup,
  FormSelect,
  FormCheck,
  FormControl,
  Card,
  Table,
} from 'react-bootstrap';
import auth from '../config/FirebaseConfig';
import { formatDateTime } from '../utils/helpers/commons';
import { DetailsStdComponent } from '../utils/helpers/ComponentBuilder';

interface ISelectedPreferredRange {
  value: string;
  selected: boolean;
}

enum PromoCodeType {
  FIXED_PRICE = 'FIXED_PRICE',
  DISCOUNTED_VALUE = 'DISCOUNTED_VALUE',
  DISCOUNTED_PERCENTAGE = 'DISCOUNTED_PERCENTAGE',
}

interface IPromoCodeDto {
  id?: string;
  code: string;
  usageCount: number | null;
  type: PromoCodeType;
  value: number;
  expiresAt: string;
}

const DEFAULT_PROMO_CODE: IPromoCodeDto = {
  code: 'XXX',
  usageCount: null,
  type: PromoCodeType.FIXED_PRICE,
  value: 1,
  expiresAt: new Date().toISOString(),
};

// Max orders per day
const COURIER_MAX_ORDERS_PER_DAY = 'COURIER_MAX_ORDERS_PER_DAY';
const MOVING_MAX_ORDERS_PER_DAY = 'MOVING_MAX_ORDERS_PER_DAY';
const BUY_FOR_ME_MAX_ORDERS_PER_DAY = 'BUY_FOR_ME_MAX_ORDERS_PER_DAY';

const PREFERRED_PICKUP_TIMES = 'PREFERRED_PICKUP_TIMES';
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const PREFERRED_PICKUP_TIME_RANGES =
  '07:00-07:30,07:30-08:00,08:00-08:30,08:30-09:00,09:00-09:30,09:30-10:00,10:00-10:30,10:30-11:00,11:00-11:30,11:30-12:00,12:00-12:30,12:30-13:00,13:00-13:30,13:30-14:00,14:00-14:30,14:30-15:00,15:00-15:30,15:30-16:00,16:00-16:30,16:30-17:00,17:00-17:30,17:30-18:00,18:00-18:30,18:30-19:00,19:00-19:30,19:30-20:00,20:00-20:30,20:30-21:00,21:00-21:30,21:30-22:00,22:00-22:30,22:30-23:00';
const CommonSettings: React.FC = () => {
  const [newPreferredPickupRange, setNewPreferredPickupRange] =
    useState<string>('07:00-07:30');
  const [preferredPickupRanges, setPreferredPickupRanges] = useState<
    ISelectedPreferredRange[]
  >([]);
  const [toggleAddNewPromoCode, setToggleAddNewPromoCode] =
    useState<boolean>(true);
  const [promoCodes, setPromoCodes] = useState<IPromoCodeDto[]>([]);
  const [courierMaxOrdersPerDay, setCourierMaxOrdersPerDay] =
    useState<number>(0);
  const [movingMaxOrdersPerDay, setMovingMaxOrdersPerDay] = useState<number>(0);
  const [newPromoCode, setNewPromoCode] =
    useState<IPromoCodeDto>(DEFAULT_PROMO_CODE);
  const [buyForMeMaxOrdersPerDay, setBuyForMeMaxOrdersPerDay] =
    useState<number>(0);
  const updateConfig = async (key: string, value: string) => {
    try {
      const userToken = await auth.currentUser?.getIdToken();
      await axios.patch(
        `${baseUrl}/admin/v1/config/update/${key}`,
        { value },
        { headers: { Authorization: `Bearer ${userToken}` } },
      );
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const userToken = await auth.currentUser?.getIdToken();
        const res = await axios.get(`${baseUrl}/customer/v1/settings`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setPreferredPickupRanges(
          (res.data.preferredPickupTimesV2 as string[]).map((r) => {
            return {
              value: r,
              selected: false,
            };
          }),
        );
        setCourierMaxOrdersPerDay(res.data.maxOrdersPerDay.courier);
        setMovingMaxOrdersPerDay(res.data.maxOrdersPerDay.moving);
        setBuyForMeMaxOrdersPerDay(res.data.maxOrdersPerDay.buyForMe);
        const promosRes = await axios.get(
          `${baseUrl}/admin/v1/orders/promo-codes`,
          { headers: { Authorization: `Bearer ${userToken}` } },
        );
        setPromoCodes(promosRes.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    fetchSettings();
  }, []);

  const deletePromoCode = async (promoCode: IPromoCodeDto) => {
    try {
      const userToken = await auth.currentUser?.getIdToken();
      await axios.delete(
        `${baseUrl}/admin/v1/orders/promo-codes/${promoCode.id}`,
        { headers: { Authorization: `Bearer ${userToken}` } },
      );
      setPromoCodes(promoCodes.filter((p) => p.id !== promoCode.id));
    } catch (error) {
      console.error('Error deleting promo code:', error);
    }
  };
  const addNewPromoCode = async () => {
    try {
      const userToken = await auth.currentUser?.getIdToken();
      const res = await axios.post(
        `${baseUrl}/admin/v1/orders/promo-codes`,
        newPromoCode,
        { headers: { Authorization: `Bearer ${userToken}` } },
      );
      setPromoCodes([...promoCodes, res.data]);
      setNewPromoCode(DEFAULT_PROMO_CODE);
      setToggleAddNewPromoCode(!toggleAddNewPromoCode);
    } catch (error) {
      console.error('Error creating promo code:', error);
    }
  };
  return (
    <NavbarWrapper>
      <Row>
        <Col>
          <InputGroup className="p-lg-3">
            <InputGroup.Text id="preferredPickupTimesTitle">
              Preferred Pickup Times
            </InputGroup.Text>
            <FormSelect
              id="preferredPickupTimesInput"
              value={newPreferredPickupRange}
              onChange={(e) => {
                setNewPreferredPickupRange(e.target.value);
              }}
            >
              <option>Please select a range</option>
              {Array.from(PREFERRED_PICKUP_TIME_RANGES.split(',')).map(
                (r, idx) => (
                  <option value={r} key={`preferred-time-option-${r}-${idx}`}>
                    {r}
                  </option>
                ),
              )}
            </FormSelect>
          </InputGroup>
        </Col>
        <Col xs>
          <Button
            variant="primary"
            onClick={() => {
              const newPreferredPickupRanges = new Set<string>();
              preferredPickupRanges.forEach((t) => {
                newPreferredPickupRanges.add(t.value);
              });
              newPreferredPickupRanges.add(newPreferredPickupRange);
              setPreferredPickupRanges(
                Array.from(newPreferredPickupRanges)
                  .sort()
                  .map((r) => {
                    return {
                      value: r,
                      selected: false,
                    };
                  }),
              );
            }}
            className="m-lg-3"
          >
            Add
          </Button>
        </Col>
        <Col xs>
          <Button
            variant="danger"
            onClick={() => {
              const newPreferredPickupRanges = preferredPickupRanges.filter(
                (t) => !t.selected,
              );
              if (newPreferredPickupRanges.length > 0) {
                setPreferredPickupRanges(newPreferredPickupRanges);
              }
            }}
            className="m-lg-3"
          >
            Remove Selected
          </Button>
        </Col>
      </Row>
      <Row>
        {preferredPickupRanges.map((time, idx) => {
          console.log(`id: ${time}-${idx}`);
          return (
            <Col
              className="m-lg-3"
              md={3}
              key={`preferred-time-col-${time}-${idx}`}
            >
              <InputGroup>
                <FormCheck
                  inline
                  label={time.value}
                  name={time.value}
                  type="checkbox"
                  id={time.value}
                  checked={time.selected}
                  onChange={(e) => {
                    const newPreferredPickupRanges = [...preferredPickupRanges];
                    newPreferredPickupRanges[idx].selected = e.target.checked;
                    if (newPreferredPickupRanges.length > 1) {
                      setPreferredPickupRanges(newPreferredPickupRanges);
                    }
                  }}
                />
              </InputGroup>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col xs>
          <Button
            variant="primary"
            onClick={() =>
              updateConfig(
                PREFERRED_PICKUP_TIMES,
                preferredPickupRanges.map((t) => t.value).join(','),
              )
            }
            className="m-lg-3"
          >
            Update preferred pickup times
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <InputGroup className="p-lg-3">
            <InputGroup.Text id="courier-max-orders-per-day">
              Courier Max Orders Per Day
            </InputGroup.Text>
            <FormControl
              placeholder="100"
              aria-label="100"
              aria-describedby="Courier Max Orders Per Day"
              type="number"
              value={courierMaxOrdersPerDay}
              onChange={(e: any) => {
                setCourierMaxOrdersPerDay(e.target.value);
              }}
            />
          </InputGroup>
        </Col>
        <Col xs>
          <Button
            variant="primary"
            onClick={() =>
              updateConfig(
                COURIER_MAX_ORDERS_PER_DAY,
                courierMaxOrdersPerDay.toString(),
              )
            }
            className="m-lg-3"
          >
            Update
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup className="p-lg-3">
            <InputGroup.Text id="moving-max-orders-per-day">
              Moving Max Orders Per Day
            </InputGroup.Text>
            <FormControl
              placeholder="100"
              aria-label="100"
              aria-describedby="Moving Max Orders Per Day"
              type="number"
              value={movingMaxOrdersPerDay}
              onChange={(e: any) => {
                setMovingMaxOrdersPerDay(e.target.value);
              }}
            />
          </InputGroup>
        </Col>
        <Col xs>
          <Button
            variant="primary"
            onClick={() =>
              updateConfig(
                MOVING_MAX_ORDERS_PER_DAY,
                movingMaxOrdersPerDay.toString(),
              )
            }
            className="m-lg-3"
          >
            Update
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup className="p-lg-3">
            <InputGroup.Text id="buy-for-me-max-orders-per-day">
              Buy For Me Max Orders Per Day
            </InputGroup.Text>
            <FormControl
              placeholder="100"
              aria-label="100"
              aria-describedby="Buy For Me Max Orders Per Day"
              type="number"
              value={buyForMeMaxOrdersPerDay}
              onChange={(e: any) => {
                setBuyForMeMaxOrdersPerDay(e.target.value);
              }}
            />
          </InputGroup>
        </Col>
        <Col xs>
          <Button
            variant="primary"
            onClick={() =>
              updateConfig(
                BUY_FOR_ME_MAX_ORDERS_PER_DAY,
                buyForMeMaxOrdersPerDay.toString(),
              )
            }
            className="m-lg-3"
          >
            Update
          </Button>
        </Col>
      </Row>
      <hr />
      <Card body>Promo Codes</Card>
      <Row hidden={toggleAddNewPromoCode}>
        <Col sm="12">
          {DetailsStdComponent(
            newPromoCode,
            'code',
            setNewPromoCode,
            'Code Name',
            false,
          )}
        </Col>
        <Col sm="12">
          {DetailsStdComponent(
            newPromoCode,
            'usageCount',
            setNewPromoCode,
            'Usage Count',
            false,
          )}
        </Col>
        <Col sm="12">
          {DetailsStdComponent(
            newPromoCode,
            'type',
            setNewPromoCode,
            'Type',
            false,
            undefined,
            [
              PromoCodeType.FIXED_PRICE,
              PromoCodeType.DISCOUNTED_VALUE,
              PromoCodeType.DISCOUNTED_PERCENTAGE,
            ],
          )}
        </Col>
        <Col sm="12">
          {DetailsStdComponent(
            newPromoCode,
            'value',
            setNewPromoCode,
            'Value',
            false,
          )}
        </Col>
        <Col sm="12">
          {DetailsStdComponent(
            newPromoCode,
            'expiresAt',
            setNewPromoCode,
            'Expires At',
            false,
          )}
        </Col>
        <Col xs="12">
          <Button
            variant="primary"
            onClick={addNewPromoCode}
            className="m-lg-3"
          >
            Add Promo Code
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="m-lg-3"
            variant={toggleAddNewPromoCode ? 'success' : 'danger'}
            onClick={() => {
              setNewPromoCode(DEFAULT_PROMO_CODE);
              setToggleAddNewPromoCode(!toggleAddNewPromoCode);
            }}
          >
            {toggleAddNewPromoCode ? '+' : 'Cancel'}
          </Button>
        </Col>
      </Row>
      <hr />
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Code</th>
            <th>Usage Count</th>
            <th>Type</th>
            <th>Value</th>
            <th>Expires At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {promoCodes.map((promoCode, idx) => (
            <tr key={`promo-code-${promoCode.id}-${idx}`}>
              <td>{promoCode.code}</td>
              <td>{promoCode.usageCount}</td>
              <td>{promoCode.type}</td>
              <td>{promoCode.value}</td>
              <td>{`${formatDateTime(new Date(promoCode.expiresAt))}`}</td>
              <td>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => deletePromoCode(promoCode)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </NavbarWrapper>
  );
};

export default CommonSettings;
