/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import auth from '../config/FirebaseConfig';
import NavbarWrapper from './Layout';
import { useNavigate } from 'react-router-dom';
import {
  PREFERRED_PICKUP_TIMES_PRECISION_IN_MINUTES,
  formatDate,
  formatTimeRange,
  toUtcTz,
} from '../utils/helpers/commons';

interface Order {
  id: string;
  seq: number;
  contactInfo: string;
  category: string;
  status: string;
  createdAt: string;
  paymentMethod: string;
  assignee: string;
  price: string;
  description: string;
  pickUpAddress: string;
  pickUpAddressDescription: string;
  preferredPickupTimes: string[];
  pickUpAddressPhone: string;
  pickUpDetails: string;
  dropOffAddress: string;
  dropOffAddressDescription: string;
  dropOffAddressPhone: string;
  dropOffDetails: string;
  buyForMeServiceDetails?: any;
}

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const statuses = new Map<string, { value: string; title: string }>();
statuses.set('REQUESTED', {
  value: 'PICK_UP_IN_PROGRESS',
  title: 'In the way to pick up',
});
// Todo Remove Legacy
statuses.set('IN_PROGRESS', {
  value: 'PICK_UP_REACHED',
  title: 'Reached pickup',
});
statuses.set('PICK_UP_IN_PROGRESS', {
  value: 'PICK_UP_REACHED',
  title: 'Reached pickup',
});
statuses.set('PICK_UP_REACHED', {
  value: 'PICKED_UP',
  title: 'Have the shipment elements',
});
statuses.set('PICKED_UP', {
  value: 'DROP_OFF_IN_PROGRESS',
  title: 'On the way to drop off',
});
statuses.set('DROP_OFF_IN_PROGRESS', {
  value: 'DROP_OFF_REACHED',
  title: 'Reached drop off',
});
statuses.set('DROP_OFF_REACHED', {
  value: 'DELIVERED',
  title: 'Delivered',
});
const BuyForMeOrdersComponent: React.FC = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<Order[]>([]);
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Fetch orders from the API
        const userToken = await auth.currentUser?.getIdToken();
        const res = await axios.get(
          `${baseUrl}/admin/v1/orders?offset=0&limit=100`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        );
        console.log(res.data.data);
        setOrders(
          (res.data.data as unknown as Array<Order>).filter(
            (o) =>
              o.buyForMeServiceDetails &&
              !o.buyForMeServiceDetails?.confirmedPayment,
          ),
        );
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);
  const updateOrderProgress = async (
    idx: number,
    orderId: string,
    orderStatus: string,
  ) => {
    const userToken = await auth.currentUser?.getIdToken();
    try {
      const res = await axios.patch(
        `${baseUrl}/courier/v1/orders/${orderId}/status`,
        { status: orderStatus },
        { headers: { Authorization: `Bearer ${userToken}` } },
      );
      const updatedOrders = [...orders];
      if (res.data.status === 'DELIVERED') {
        updatedOrders.splice(idx, 1);
      } else {
        const updatedOrder = {
          ...updatedOrders[idx],
          status: res.data.status,
          assignee: res.data.assignee,
        };
        updatedOrders[idx] = updatedOrder;
      }
      setOrders(updatedOrders);
    } catch (e: unknown) {
      console.error('Error updating progress:', e);
    }
  };
  const resetOrderProgress = async (idx: number, orderId: string) => {
    const userToken = await auth.currentUser?.getIdToken();
    try {
      const res = await axios.patch(
        `${baseUrl}/admin/v1/orders/${orderId}/reset-status`,
        { status: 'REQUESTED' },
        { headers: { Authorization: `Bearer ${userToken}` } },
      );
      const updatedOrders = [...orders];
      const updatedOrder = {
        ...updatedOrders[idx],
        status: res.data.status,
      };
      updatedOrders[idx] = updatedOrder;
      setOrders(updatedOrders);
    } catch (e: unknown) {
      console.error('Error resetting progress:', e);
    }
  };
  const updateProgressAction = (order: Order, index: number) => {
    const updateProgressButton =
      order.buyForMeServiceDetails &&
      order.status !== 'DROP_OFF_REACHED' &&
      order.status !== 'DELIVERED' ? (
        <Button
          variant={order.status === 'DROP_OFF_REACHED' ? 'success' : 'primary'}
          size="sm"
          onClick={() =>
            updateOrderProgress(
              index,
              order.id,
              statuses.get(order.status)?.value || '',
            )
          }
          disabled={order.status === 'DELIVERED'}
        >
          {statuses.get(order.status)?.title}
        </Button>
      ) : null;
    return updateProgressButton;
  };
  const buyForMeServiceDetailsAction = (order: Order, _idx: number) => {
    console.log(order.buyForMeServiceDetails);
    const buyForMeServiceDetailsButton = order.buyForMeServiceDetails ? (
      <Button
        variant="success"
        size="sm"
        onClick={() =>
          navigate('/buy-for-me-service-details', {
            state: { order: order },
          })
        }
      >
        Buy For Me Details
      </Button>
    ) : null;
    return buyForMeServiceDetailsButton;
  };
  const resetProgressAction = (order: Order, index: number) => {
    const resetProgressButton =
      order.buyForMeServiceDetails &&
      order.status !== 'DROP_OFF_REACHED' &&
      order.status !== 'DELIVERED' ? (
        <Button
          variant={'warning'}
          size="sm"
          onClick={() => resetOrderProgress(index, order.id)}
          disabled={order.status === 'DELIVERED'}
        >
          Reset Progress
        </Button>
      ) : null;
    return resetProgressButton;
  };

  return (
    <NavbarWrapper>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Contact Info</th>
            <th>Description</th>
            <th>PickUp Location</th>
            <th>DropOff Location</th>
            <th>Status</th>
            <th>Pickup Date</th>
            <th>Pickup Times</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={order.seq}>
              <td>{order.seq}</td>
              <td>{order.contactInfo}</td>
              <td>{order.description}</td>
              <td>{order.pickUpAddress}</td>
              <td>{order.dropOffAddress}</td>
              <td>{order.status}</td>
              <td>{formatDate(toUtcTz(order.preferredPickupTimes[0]))}</td>
              <td>
                {order.preferredPickupTimes
                  .map((d) =>
                    formatTimeRange(
                      toUtcTz(d),
                      PREFERRED_PICKUP_TIMES_PRECISION_IN_MINUTES,
                    ),
                  )
                  .join(' ')}
              </td>
              <td>{updateProgressAction(order, index)}</td>
              <td>{buyForMeServiceDetailsAction(order, index)}</td>
              <td>{resetProgressAction(order, index)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </NavbarWrapper>
  );
};

export default BuyForMeOrdersComponent;
