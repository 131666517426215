import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import { initializeAuth, browserLocalPersistence } from 'firebase/auth';

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_CLIENT_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_CLIENT_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_CLIENT_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_CONFIG_CLIENT_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_CONFIG_CLIENT_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_CONFIG_CLIENT_MEASUREMENT_ID,
});
const auth = initializeAuth(app, { persistence: browserLocalPersistence });

export default auth;
