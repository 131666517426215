/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import CookieConsent from './components/CookieConsent';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import ProtectedRoutes from './utils/ProtectedRoutes';
import NotFound from './components/NotFound';
import Login from './components/Login';
import Orders from './components/CourierOrders';
import CourierPricingSettings from './components/CourierPricingSettings';
import auth from './config/FirebaseConfig';
import MovingServiceDetails from './components/MovingServiceDetails';
import MovingOrders from './components/MovingOrders';
import MovingPricingSettings from './components/MovingPricingSettings';
import CenteredSpinner from './utils/Spinner';
import { CtxProvider } from './context/Ctx';
import GlobalAlert from './utils/Alert';
import CourierServiceDetails from './components/CourierServiceDetails';
import BuyForMePricingSettings from './components/BuyForMePricingSettings';
import BuyForMeServiceDetails from './components/BuyForMeServiceDetails';
import BuyForMeOrdersComponent from './components/BuyForMeOrders';
import CommonSettings from './components/CommonSettings';

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (_user) => {
      setLoading(false);
    });
    return () => unsubscribe();
  });
  if (loading) {
    return <CenteredSpinner hidden={loading} />;
  }
  return (
    <CtxProvider>
      <div className="App">
        <GlobalAlert />
        <CenteredSpinner />
        <Router>
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<Navigate to="/courier-orders" />} />
              <Route path="/courier-orders" element={<Orders />} />
              <Route
                path="/courier-service-details"
                element={<CourierServiceDetails />}
              />
              <Route
                path="/courier-pricing-settings"
                element={<CourierPricingSettings />}
              />
              <Route path="/moving-orders" element={<MovingOrders />} />
              <Route
                path="/moving-service-details"
                element={<MovingServiceDetails />}
              />
              <Route
                path="/moving-pricing-settings"
                element={<MovingPricingSettings />}
              />
              <Route
                path="/buy-for-me-orders"
                element={<BuyForMeOrdersComponent />}
              />
              <Route
                path="/buy-for-me-service-details"
                element={<BuyForMeServiceDetails />}
              />
              <Route
                path="/buy-for-me-pricing-settings"
                element={<BuyForMePricingSettings />}
              />
              <Route path="/common-settings" element={<CommonSettings />} />
            </Route>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
        <CookieConsent />
      </div>
    </CtxProvider>
  );
};

export default App;
