/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useState } from 'react';

// Define a type for your context data
export type CtxDto = {
  loading: boolean;
  alert: {
    show: boolean;
    message: string;
    variant: string;
  };
};

type CtxType = {
  ctx: CtxDto;
  update: (key: string, newValue: any) => void;
};

export const ctxKeys = {
  loading: 'loading',
  alert: 'alert',
};

export const alertVariants = {
  success: 'success',
  danger: 'danger',
  info: 'info',
};

const initialVal: CtxType = {
  ctx: {
    loading: false,
    alert: {
      show: false,
      message: '',
      variant: alertVariants.info,
    },
  },
  update: (_key: string, _newValue: any) => void {},
};

// Create a context with an initial value
const Ctx = createContext<CtxType>(initialVal);

// Create a provider component for the context
export const CtxProvider = ({ children }: { children: React.ReactNode }) => {
  const [ctx, setCtx] = useState<CtxDto>(initialVal.ctx);

  // Function to update the context value
  const update = (fieldName: string, newValue: any) => {
    setCtx({
      ...ctx,
      [fieldName]: newValue,
    });
  };

  const context: CtxType = {
    ctx,
    update,
  };
  return (
    <div>
      <Ctx.Provider value={context}>{children}</Ctx.Provider>
    </div>
  );
};

// Custom hook to consume the context
export const useCtx = (): CtxType => {
  const context = useContext<CtxType>(Ctx);
  if (!context) {
    throw new Error('useCtx must be used within a CtxProvider');
  }
  return context;
};
