import { Alert, Col, Container, Row } from 'react-bootstrap';
import { alertVariants, ctxKeys, useCtx } from '../context/Ctx';

const GlobalAlert = () => {
  const { ctx, update } = useCtx();
  const handleCloseAlert = () => {
    update(ctxKeys.alert, {
      show: false,
      message: '',
      variant: alertVariants.info,
    });
  };
  return (
    <Container>
      <Row>
        <Col>
          <Alert
            hidden={!ctx.alert.show}
            variant={ctx.alert.variant}
            onClose={handleCloseAlert}
            dismissible
          >
            {ctx.alert.message}
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default GlobalAlert;
