import { ElementType } from 'react';
import {
  Row,
  Col,
  FormControl,
  InputGroup,
  FormCheck,
  Image,
  FormSelect,
} from 'react-bootstrap';
import { addMinutes } from 'date-fns';
import {
  formatDate,
  formatTime,
  formatDateTime,
  toUtcTz,
  PREFERRED_PICKUP_TIMES_PRECISION_IN_MINUTES,
} from './commons';

const TEXT_AREA_FIELDS: string[] = [
  'description',
  'contactInfo',
  'dropOffAddress',
  'pickUpAddress',
  'addressDescription',
  'assignee',
];

export const handleInputChange = (
  obj: any,
  fieldName: string,
  value: string | number | boolean,
  setValue: (obj: any) => void,
) => {
  const newValue = { ...obj };
  newValue[fieldName] = value;
  setValue(newValue);
};

const handleFieldValue = (fieldName: string, value: string): string => {
  if (fieldName === 'createdAt' || fieldName === 'updatedAt') {
    return formatDateTime(new Date(value));
  }
  if (fieldName === 'expiresAt') {
    return value;
  }
  if (
    value &&
    !value.toString().endsWith('.') &&
    !Number.isNaN(parseFloat(value)) &&
    !fieldName.toLocaleLowerCase().includes('phone')
  ) {
    return parseFloat(value).toString();
  }
  return value;
};

const handleFieldType = (fieldName: string): ElementType<any> | undefined => {
  if (TEXT_AREA_FIELDS.includes(fieldName)) {
    return 'textarea';
  }
  return undefined;
};

export const DetailsStdComponent = (
  obj: any,
  fieldName: string,
  setValue: (obj: any) => void,
  title: string | null,
  isView: boolean,
  token?: string,
  dropDownOptions?: string[],
) => {
  console.log(obj);
  let component = null;
  if (typeof obj[fieldName] === 'boolean' && title) {
    component = (
      <InputGroup className="p-lg-3">
        <FormCheck
          inline
          label={title}
          name={fieldName}
          type="checkbox"
          id={fieldName}
          checked={obj[fieldName] as boolean}
          disabled={isView}
          onChange={(e) =>
            handleInputChange(obj, fieldName, e.target.checked, setValue)
          }
        />
      </InputGroup>
    );
  } else if (dropDownOptions) {
    component = (
      <InputGroup className="p-lg-3">
        {title ? (
          <InputGroup.Text id={fieldName}>{title}</InputGroup.Text>
        ) : null}
        <FormSelect
          value={handleFieldValue(fieldName || '', obj[fieldName] || '')}
          disabled={isView}
          onChange={(e) =>
            handleInputChange(
              obj,
              fieldName,
              typeof obj[fieldName] === 'number'
                ? Number(e.target.value)
                : e.target.value,
              setValue,
            )
          }
        >
          {dropDownOptions.map((s) => (
            <option>{s}</option>
          ))}
        </FormSelect>
      </InputGroup>
    );
  } else if (fieldName === 'files' && obj[fieldName] && token) {
    component = (
      <>
        {obj[fieldName].map((file: { url: any }, idx: number) => {
          const srcUrl = `${file.url}?authorization=${token}`;
          return (
            <Row key={idx}>
              <Col>
                <Image key={idx} className="m-lg-3" rounded src={srcUrl} />
              </Col>
            </Row>
          );
        })}
      </>
    );
  } else if (fieldName === 'preferredPickupTimes' && obj[fieldName]) {
    component = (
      <>
        <Row>
          <Col>
            <InputGroup className="p-lg-3">
              <InputGroup.Text id={fieldName}>Date And Times</InputGroup.Text>
              <FormControl
                type={'text'}
                value={formatDate(toUtcTz(obj[fieldName][0]))}
                disabled={true}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          {obj[fieldName].map((dateTime: string, idx: number) => {
            return (
              <Col md={'6'} key={idx}>
                <InputGroup className="p-lg-3">
                  <FormControl
                    type={'text'}
                    value={`${formatTime(toUtcTz(dateTime))} - ${formatTime(
                      addMinutes(
                        toUtcTz(dateTime),
                        PREFERRED_PICKUP_TIMES_PRECISION_IN_MINUTES,
                      ),
                    )}`}
                    disabled={true}
                  />
                </InputGroup>
              </Col>
            );
          })}
        </Row>
      </>
    );
  } else {
    component = (
      <InputGroup className="p-lg-3">
        {title ? (
          <InputGroup.Text id={fieldName}>{title}</InputGroup.Text>
        ) : null}
        <FormControl
          type={typeof obj[fieldName] === 'number' ? 'number' : 'text'}
          value={handleFieldValue(fieldName || '', obj[fieldName] || '')}
          as={handleFieldType(fieldName)}
          disabled={isView}
          onChange={(e) =>
            handleInputChange(
              obj,
              fieldName,
              typeof obj[fieldName] === 'number'
                ? Number(e.target.value)
                : e.target.value,
              setValue,
            )
          }
        />
      </InputGroup>
    );
  }
  return component;
};
