import React, { useState } from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import {
  signInWithEmailAndPassword,
  signOut,
  IdTokenResult,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import auth from '../config/FirebaseConfig';

type LoginFormProps = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const [formValues, setFormValues] = useState<LoginFormProps>({
    email: '',
    password: '',
  });

  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleToggleAlert = () => {
    setShowAlert(!showAlert);
    setAlertMessage('');
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof LoginFormProps,
  ) => {
    setFormValues({ ...formValues, [fieldName]: event.target.value });
  };

  const loginToFirebase = async (formData: LoginFormProps) => {
    try {
      const result = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password,
      ).then((payload) => payload.user.getIdTokenResult());
      const roles = (result as IdTokenResult).claims?.roles?.split(' ') || [];
      if (roles.includes('ADMIN')) {
        navigate('/courier-orders');
      } else {
        setAlertMessage('Only admins can login');
        setShowAlert(true);
        await signOut(auth);
      }
    } catch (e: any) {
      setAlertMessage(e.message);
      setShowAlert(true);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission logic here
    loginToFirebase(formValues);
  };

  return (
    <div
      className="container d-flex align-items-center justify-content-center"
      style={{ minHeight: '100vh' }}
    >
      <div className="col-md-6">
        <Card className="shadow">
          <Card.Body>
            <h1 className="text-center mb-4">Login</h1>
            {showAlert && (
              <Alert variant="danger" onClose={handleToggleAlert} dismissible>
                {alertMessage}
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={formValues.email}
                  onChange={(e) =>
                    handleChange(
                      e as React.ChangeEvent<HTMLInputElement>,
                      'email',
                    )
                  }
                  placeholder="Enter email"
                />
              </Form.Group>

              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={formValues.password}
                  onChange={(e) =>
                    handleChange(
                      e as React.ChangeEvent<HTMLInputElement>,
                      'password',
                    )
                  }
                  placeholder="Enter password"
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="w-100 mt-4">
                Sign In
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Login;
