import { Spinner } from 'react-bootstrap';
import { useCtx } from '../context/Ctx';

const CenteredSpinner = (props: { hidden?: boolean }) => {
  const { ctx } = useCtx();
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Spinner
        hidden={props.hidden || !ctx.loading}
        animation="border"
        variant="primary"
      />
    </div>
  );
};

export default CenteredSpinner;
