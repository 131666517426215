import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const NotFound: React.FC = () => {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ height: '100vh' }}
    >
      <Row>
        <Col className="text-center">
          <h1>404</h1>
          <h2>Page Not Found</h2>
          <p>Oops! The page you requested could not be found.</p>
          <Button variant="primary" href="/">
            Go Back to Home
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
