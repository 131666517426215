/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import NavbarWrapper from './Layout';
import axios from 'axios';
import {
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
  Card,
  Table,
  FormSelect,
  FormCheck,
} from 'react-bootstrap';
import auth from '../config/FirebaseConfig';
import Decimal from 'decimal.js';

const configsMap = new Map<string, { key: string; title: string }>();
configsMap.set('driverCost', {
  key: 'BUY_FOR_ME_TRANSPORT_VEHICLE_DRIVER_COST',
  title: 'Vehicle Driver Cost',
});
configsMap.set('vehicleOpenRate', {
  key: 'BUY_FOR_ME_TRANSPORT_VEHICLE_OPEN_RATE',
  title: 'Vehicle Open Rate',
});
configsMap.set('vehicleRatePerKm', {
  key: 'BUY_FOR_ME_TRANSPORT_VEHICLE_RATE_PER_KM',
  title: 'Vehicl Rate Per Km',
});
configsMap.set('vehicleAvgWaitingCost', {
  key: 'BUY_FOR_ME_TRANSPORT_VEHICLE_AVG_WAITING_COST',
  title: 'Vehicle Average Waiting Cost',
});
configsMap.set('payingCommission', {
  key: 'BUY_FOR_ME_PAYING_COMMISSION',
  title: 'Paying Commission',
});
configsMap.set('buyForMeCommission', {
  key: 'BUY_FOR_ME_COMMISSION',
  title: 'Flieger Service Commission',
});

interface IOrderWeightRangeEnd {
  endRange: number | null;
  factor: number;
}

interface IOrderWeightRange {
  min: number;
  max: number | undefined;
  factor: number;
}

interface ICategoryDetails {
  category: string;
  price: string;
  active: boolean;
  maxWeightInKg: string;
}

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const BuyForMePricingSettings: React.FC = () => {
  const [configs, setConfigs] = useState<any>({});
  const updateConfig = async (key: string, value: string) => {
    try {
      const userToken = await auth.currentUser?.getIdToken();
      await axios.patch(
        `${baseUrl}/admin/v1/config/update/${key}`,
        { value },
        { headers: { Authorization: `Bearer ${userToken}` } },
      );
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };
  const [buyForMeOrderWeightRanges, setBuyForMeOrderWeightRanges] = useState<
    IOrderWeightRange[]
  >([]);
  const addBuyForMeWeightRange = () => {
    const lastEl =
      buyForMeOrderWeightRanges[buyForMeOrderWeightRanges.length - 1];
    if (lastEl.max && lastEl.max > lastEl.min) {
      const newDistR = [...buyForMeOrderWeightRanges];
      newDistR.push({
        min: lastEl.max,
        max: undefined,
        factor: 0,
      });
      setBuyForMeOrderWeightRanges(newDistR);
    }
  };
  const removeBuyForMeWeightRange = () => {
    if (buyForMeOrderWeightRanges.length > 1) {
      buyForMeOrderWeightRanges.splice(buyForMeOrderWeightRanges.length - 1, 1);
      buyForMeOrderWeightRanges[buyForMeOrderWeightRanges.length - 1].max =
        undefined;
      setBuyForMeOrderWeightRanges([...buyForMeOrderWeightRanges]);
    }
  };
  const updateBuyForMeOrderWeightRanges = async () => {
    const weightRanges: IOrderWeightRangeEnd[] = buyForMeOrderWeightRanges.map(
      (r) => {
        return {
          endRange: r.max || null,
          factor: r.factor,
        };
      },
    );
    try {
      const userToken = await auth.currentUser?.getIdToken();
      await axios.patch(
        `${baseUrl}/admin/v1/config/buy-for-me-weight-ranges`,
        weightRanges,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      );
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };
  const [buyForMeServiceCategories, setBuyForMeServiceCategories] = useState<
    ICategoryDetails[]
  >([]);
  const updateBuyForMeServiceCategory = async (category: ICategoryDetails) => {
    try {
      const userToken = await auth.currentUser?.getIdToken();
      await axios.patch(
        `${baseUrl}/admin/v1/config/buy-for-me-categories`,
        category,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      );
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const userToken = await auth.currentUser?.getIdToken();
        const res = await axios.get(`${baseUrl}/customer/v1/settings`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setConfigs(res.data.buyForMeServiceSettings);
        setBuyForMeOrderWeightRanges(res.data.buyForMeOrderWeightRanges);
        setBuyForMeServiceCategories(res.data.buyForMeServiceCategories);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    fetchSettings();
  }, []);
  return (
    <NavbarWrapper>
      {Array.from(configsMap.keys()).map((fieldName) => (
        <Row>
          <Col>
            <InputGroup className="p-lg-3">
              <InputGroup.Text id={fieldName}>
                {configsMap.get(fieldName)?.title}
              </InputGroup.Text>
              <FormControl
                type={
                  typeof configs[fieldName] === 'number' ? 'number' : 'text'
                }
                value={configs[fieldName]}
                onChange={(e: any) => {
                  const newConfig = { ...configs };
                  newConfig[fieldName] = e.target.value;
                  setConfigs(newConfig);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs>
            <Button
              variant="primary"
              onClick={() =>
                updateConfig(
                  configsMap.get(fieldName)?.key || '',
                  configs[fieldName] || '',
                )
              }
              className="m-lg-3"
            >
              Update
            </Button>
          </Col>
        </Row>
      ))}
      <hr />
      <Card body>Order Weight Ranges</Card>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Start</th>
            <th>End</th>
            <th>Factor</th>
          </tr>
        </thead>
        <tbody>
          {buyForMeOrderWeightRanges.map((r, idx) => (
            <tr key={`${r.min}-${idx}`}>
              <td>
                <FormControl
                  type="number"
                  disabled
                  value={idx > 0 ? buyForMeOrderWeightRanges[idx - 1].max : 0}
                />
              </td>
              <td>
                <InputGroup>
                  <FormControl
                    type="number"
                    placeholder={r?.max?.toString() || 'Anything Above'}
                    value={r.max || ''}
                    onChange={(e: any) => {
                      const newVal = parseFloat(e.target.value);
                      const curr = buyForMeOrderWeightRanges[idx];
                      // if (
                      //   idx > 0 &&
                      //   newVal > (buyForMeOrderWeightRanges[idx - 1]?.max || 0)
                      // ) {
                      const updatedRange = {
                        ...curr,
                        max: newVal,
                      };
                      buyForMeOrderWeightRanges[idx] = updatedRange;
                      setBuyForMeOrderWeightRanges([
                        ...buyForMeOrderWeightRanges,
                      ]);
                      if (
                        !curr.max ||
                        buyForMeOrderWeightRanges.length - 1 === idx
                      ) {
                        addBuyForMeWeightRange();
                      }
                      // }
                    }}
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup>
                  <FormControl
                    type="number"
                    value={r.factor}
                    onChange={(e: any) => {
                      const newVal = parseFloat(e.target.value);
                      const curr = buyForMeOrderWeightRanges[idx];
                      const updatedRange = {
                        ...curr,
                        factor: newVal,
                      };
                      buyForMeOrderWeightRanges[idx] = updatedRange;
                      setBuyForMeOrderWeightRanges([
                        ...buyForMeOrderWeightRanges,
                      ]);
                    }}
                  />
                </InputGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        <Col xs>
          <Button variant="danger" onClick={() => removeBuyForMeWeightRange()}>
            Remove Weight Range
          </Button>
          <Button
            variant="primary"
            onClick={() => updateBuyForMeOrderWeightRanges()}
          >
            Update Weight Ranges
          </Button>
        </Col>
      </Row>
      <Card body>Order Categories</Card>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Category</th>
            <th>Price</th>
            <th>Max Weight</th>
            <th>Active</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {buyForMeServiceCategories.map((c, idx) => (
            <tr key={`${c.category}-${idx}`}>
              <td>
                <FormControl type="text" disabled value={c.category} />
              </td>
              <td>
                <InputGroup>
                  <FormControl
                    type="number"
                    value={parseFloat(c.price) || ''}
                    onChange={(e: any) => {
                      const curr = buyForMeServiceCategories[idx];
                      const updatedCategory: ICategoryDetails = {
                        ...curr,
                        price: e.target.value,
                      };
                      buyForMeServiceCategories[idx] = updatedCategory;
                      setBuyForMeServiceCategories([
                        ...buyForMeServiceCategories,
                      ]);
                    }}
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup>
                  <FormControl
                    type="number"
                    value={parseFloat(c.maxWeightInKg) || ''}
                    onChange={(e: any) => {
                      const curr = buyForMeServiceCategories[idx];
                      const updatedCategory: ICategoryDetails = {
                        ...curr,
                        maxWeightInKg: e.target.value,
                      };
                      buyForMeServiceCategories[idx] = updatedCategory;
                      setBuyForMeServiceCategories([
                        ...buyForMeServiceCategories,
                      ]);
                    }}
                  />
                </InputGroup>
              </td>

              <td>
                <InputGroup className="p-lg-3">
                  <FormCheck
                    inline
                    type="checkbox"
                    checked={c.active}
                    onChange={(e: any) => {
                      const curr = buyForMeServiceCategories[idx];
                      const updatedCategory: ICategoryDetails = {
                        ...curr,
                        active: e.target.checked,
                      };
                      buyForMeServiceCategories[idx] = updatedCategory;
                      setBuyForMeServiceCategories([
                        ...buyForMeServiceCategories,
                      ]);
                    }}
                  />
                </InputGroup>
              </td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => updateBuyForMeServiceCategory(c)}
                >
                  update
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </NavbarWrapper>
  );
};

export default BuyForMePricingSettings;
