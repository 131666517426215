import React, { useEffect, useState } from 'react';
import NavbarWrapper from './Layout';
import axios from 'axios';
import {
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
  Card,
  Table,
} from 'react-bootstrap';
import auth from '../config/FirebaseConfig';

interface IOrderDistanceRangeEnd {
  endRange: number | null;
  pricePerExtraKm: number;
  rangePrice: number;
}

interface IOrderDistanceRange {
  min: number;
  max: number | undefined;
  pricePerExtraKm: number;
  rangePrice: number;
}

export interface ICategoryDetails {
  category: string;
  price: string;
}
const MARKETING_INFLATION_PERCENTAGE_FACTOR =
  'MARKETING_INFLATION_PERCENTAGE_FACTOR';
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const CourierPricingSettings: React.FC = () => {
  const [marketingInflationPercentage, setMarketingInflationPercentage] =
    useState<number>(0);
  const updateConfig = async (key: string, value: string) => {
    try {
      const userToken = await auth.currentUser?.getIdToken();
      await axios.patch(
        `${baseUrl}/admin/v1/config/update/${key}`,
        { value },
        { headers: { Authorization: `Bearer ${userToken}` } },
      );
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };
  const [courierOrderDistanceRanges, setCourierOrderDistanceRanges] = useState<
    IOrderDistanceRange[]
  >([]);
  const addCourierOrderDistanceRange = () => {
    const lastEl =
      courierOrderDistanceRanges[courierOrderDistanceRanges.length - 1];
    if (lastEl.max && lastEl.max > lastEl.min) {
      const newDistR = [...courierOrderDistanceRanges];
      newDistR.push({
        min: lastEl.max,
        max: undefined,
        pricePerExtraKm: 0,
        rangePrice: 0,
      });
      setCourierOrderDistanceRanges(newDistR);
    }
  };
  const removeCourierOrderDistanceRange = () => {
    if (courierOrderDistanceRanges.length > 1) {
      courierOrderDistanceRanges.splice(
        courierOrderDistanceRanges.length - 1,
        1,
      );
      courierOrderDistanceRanges[courierOrderDistanceRanges.length - 1].max =
        undefined;
      setCourierOrderDistanceRanges([...courierOrderDistanceRanges]);
    }
  };
  const updateCourierOrderDistanceRanges = async () => {
    const distanceRanges: IOrderDistanceRangeEnd[] =
      courierOrderDistanceRanges.map((r) => {
        return {
          rangePrice: r.rangePrice,
          pricePerExtraKm: r.pricePerExtraKm,
          endRange: r.max || null,
        };
      });
    try {
      const userToken = await auth.currentUser?.getIdToken();
      await axios.patch(
        `${baseUrl}/admin/v1/config/courier-distance-ranges`,
        distanceRanges,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      );
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };
  const [courierServiceCategories, setCourierServiceCategories] = useState<
    ICategoryDetails[]
  >([]);
  const updateCourierServiceCategory = async (category: ICategoryDetails) => {
    try {
      const userToken = await auth.currentUser?.getIdToken();
      await axios.patch(
        `${baseUrl}/admin/v1/config/courier-categories`,
        category,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      );
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const userToken = await auth.currentUser?.getIdToken();
        const res = await axios.get(`${baseUrl}/customer/v1/settings`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setMarketingInflationPercentage(
          parseFloat(res.data.marketingInflationPercentageFactor.factor),
        );
        setCourierOrderDistanceRanges(res.data.courierOrderDistanceRanges);
        setCourierServiceCategories(res.data.courierServiceCategories);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    fetchSettings();
  }, []);

  return (
    <NavbarWrapper>
      <Row>
        <Col>
          <InputGroup className="p-lg-3">
            <InputGroup.Text id="Marketing-inflation-percentage">
              Fake price appears to client as the price before discount, by
              multiply the original price by fake price factor
            </InputGroup.Text>
            <FormControl
              placeholder="200"
              aria-label="200"
              aria-describedby="Marketing Inflation %"
              type="number"
              value={marketingInflationPercentage}
              onChange={(e: any) => {
                setMarketingInflationPercentage(e.target.value);
              }}
            />
          </InputGroup>
        </Col>
        <Col xs>
          <Button
            variant="primary"
            onClick={() =>
              updateConfig(
                MARKETING_INFLATION_PERCENTAGE_FACTOR,
                marketingInflationPercentage.toString(),
              )
            }
            className="m-lg-3"
          >
            Update
          </Button>
        </Col>
      </Row>
      <hr />
      <Card body>Order Distance Ranges</Card>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Start</th>
            <th>End</th>
            <th>Price Per Extra Km</th>
            <th>Range Price</th>
          </tr>
        </thead>
        <tbody>
          {courierOrderDistanceRanges.map((r, idx) => (
            <tr key={`${r.min}-${idx}`}>
              <td>
                <FormControl
                  type="number"
                  disabled
                  value={idx > 0 ? courierOrderDistanceRanges[idx - 1].max : 0}
                />
              </td>
              <td>
                <InputGroup>
                  <FormControl
                    type="number"
                    placeholder={r?.max?.toString() || 'Anything Above'}
                    value={r.max || ''}
                    onChange={(e: any) => {
                      const newVal = parseFloat(e.target.value);
                      const curr = courierOrderDistanceRanges[idx];
                      // if (
                      //   idx > 0 &&
                      //   newVal > (courierOrderDistanceRanges[idx - 1]?.max || 0)
                      // ) {
                      const updatedRange = {
                        ...curr,
                        max: newVal,
                      };
                      courierOrderDistanceRanges[idx] = updatedRange;
                      setCourierOrderDistanceRanges([
                        ...courierOrderDistanceRanges,
                      ]);
                      if (
                        !curr.max ||
                        courierOrderDistanceRanges.length - 1 === idx
                      ) {
                        addCourierOrderDistanceRange();
                      }
                      // }
                    }}
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup>
                  <FormControl
                    type="number"
                    value={r.pricePerExtraKm}
                    onChange={(e: any) => {
                      const newVal = parseFloat(e.target.value);
                      const curr = courierOrderDistanceRanges[idx];
                      const updatedRange = {
                        ...curr,
                        pricePerExtraKm: newVal,
                      };
                      courierOrderDistanceRanges[idx] = updatedRange;
                      setCourierOrderDistanceRanges([
                        ...courierOrderDistanceRanges,
                      ]);
                    }}
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup>
                  <FormControl
                    type="number"
                    value={r.rangePrice}
                    onChange={(e: any) => {
                      const newVal = parseFloat(e.target.value);
                      const curr = courierOrderDistanceRanges[idx];
                      const updatedRange = {
                        ...curr,
                        rangePrice: newVal,
                      };
                      courierOrderDistanceRanges[idx] = updatedRange;
                      setCourierOrderDistanceRanges([
                        ...courierOrderDistanceRanges,
                      ]);
                    }}
                  />
                </InputGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        <Col xs>
          <Button
            variant="danger"
            onClick={() => removeCourierOrderDistanceRange()}
          >
            Remove Distance Range
          </Button>
          <Button
            variant="primary"
            onClick={() => updateCourierOrderDistanceRanges()}
          >
            Update Distance Ranges
          </Button>
        </Col>
      </Row>
      <Card body>Order Categories</Card>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Category</th>
            <th>Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {courierServiceCategories.map((c, idx) => (
            <tr key={`${c.category}-${idx}`}>
              <td>
                <FormControl type="text" disabled value={c.category} />
              </td>
              <td>
                <InputGroup>
                  <FormControl
                    type="number"
                    value={parseFloat(c.price) || ''}
                    onChange={(e: any) => {
                      const curr = courierServiceCategories[idx];
                      const updatedCategory: ICategoryDetails = {
                        ...curr,
                        price: e.target.value,
                      };
                      courierServiceCategories[idx] = updatedCategory;
                      setCourierServiceCategories([
                        ...courierServiceCategories,
                      ]);
                    }}
                  />
                </InputGroup>
              </td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => updateCourierServiceCategory(c)}
                >
                  update
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </NavbarWrapper>
  );
};

export default CourierPricingSettings;
