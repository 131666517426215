import React, { ReactNode } from 'react';
import { Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

interface NavbarWrapperProps {
  children: ReactNode;
}

const NavbarWrapper: React.FC<NavbarWrapperProps> = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <Nav
        activeKey={location.pathname}
        onSelect={(k) => {
          navigate(k || '/courier-orders');
        }}
        variant="tabs"
      >
        <Nav.Item>
          <Nav.Link eventKey="/courier-orders">Courier Orders</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="/moving-orders">Moving Service Orders</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="/buy-for-me-orders">
            Buy For Me Service Orders
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="/courier-pricing-settings">
            Courier Pricing Settings
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="/moving-pricing-settings">
            Moving Pricing Settings
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="/buy-for-me-pricing-settings">
            Buy For Me Pricing Settings
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="/common-settings">Common Settings</Nav.Link>
        </Nav.Item>
      </Nav>
      <div>{children}</div>
    </>
  );
};

export default NavbarWrapper;
