import React, { useEffect, useState } from 'react';
import NavbarWrapper from './Layout';
import axios from 'axios';
import { Button, Row, Col, Accordion, Card } from 'react-bootstrap';
import auth from '../config/FirebaseConfig';
import { Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import Decimal from 'decimal.js';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import {
  DetailsStdComponent,
  handleInputChange,
} from '../utils/helpers/ComponentBuilder';
import { arrEl } from '../utils/helpers/commons';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

interface Invoice {
  id: string;
  driverCost: Decimal;
  vehicleCost: Decimal;
  amountToPay: Decimal;
}
const resolveInvoiceDetails = (details: any): Invoice => {
  return {
    id: details.id,
    vehicleCost: !details.requiresTransportation
      ? new Decimal(0)
      : new Decimal(details.vehicleCost),
    driverCost: !details.requiresTransportation
      ? new Decimal(0)
      : new Decimal(details.driverCost),
    amountToPay: new Decimal(details.amountToPay),
  };
};
const BuyForMeServiceDetails: React.FC = () => {
  const location = useLocation();
  if (!location.state?.order?.buyForMeServiceDetails) {
    throw new Error('This case should never happen!');
  }
  const [order, setOrder] = useState<any>(location.state.order);
  const [token, setToken] = useState<string>('');
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const userToken = await auth.currentUser?.getIdToken();
        const res = await axios.get(
          `${baseUrl}/admin/v1/orders/${location.state.order.id}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        );
        setOrder(res.data);
        setToken(userToken || '');
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    fetchOrder();
  }, [location.state.order.id]);
  console.log(order);
  const [invoiceDetails, setInvoiceDetails] = useState<Invoice>(
    resolveInvoiceDetails(order.buyForMeServiceDetails),
  );
  const [buyForMeServiceDetails, setBuyForMeServiceDetails] = useState<any>(
    order.buyForMeServiceDetails,
  );

  const issueInvoice = async () => {
    try {
      const userToken = await auth.currentUser?.getIdToken();
      const res = await axios.post(
        `${baseUrl}/admin/v1/orders/buy-for-me-service/issue-invoice`,
        invoiceDetails,
        { headers: { Authorization: `Bearer ${userToken}` } },
      );
      setOrder(res.data);
      setBuyForMeServiceDetails(res.data.buyForMeServiceDetails);
      console.log('Details:', res.data.buyForMeServiceDetails);
      setInvoiceDetails(resolveInvoiceDetails(res.data.buyForMeServiceDetails));
    } catch (error) {
      console.error('Error Issuing Invoice:', error);
    }
  };
  const confirmPayment = async () => {
    try {
      const userToken = await auth.currentUser?.getIdToken();
      await axios.post(
        `${baseUrl}/admin/v1/orders/buy-for-me-service/confirm-payment/${buyForMeServiceDetails.id}`,
        {},
        { headers: { Authorization: `Bearer ${userToken}` } },
      );
      handleInputChange(
        buyForMeServiceDetails,
        'confirmedPayment',
        true,
        setBuyForMeServiceDetails,
      );
    } catch (error) {
      console.error('Error Issuing Invoice:', error);
    }
  };
  return (
    <NavbarWrapper>
      <Accordion>
        <AccordionItem eventKey="0">
          <AccordionHeader>Order Information</AccordionHeader>
          <AccordionBody>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'seq',
                  () => {},
                  'Order Number',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'createdAt',
                  () => {},
                  'Created At',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'contactInfo',
                  () => {},
                  'Contact Info',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(order, 'status', () => {}, 'Status', true)}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'paymentMethod',
                  () => {},
                  'Payment Method',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(order, 'price', () => {}, 'Price', true)}
              </Col>
              <Col>
                {DetailsStdComponent(order, 'currency', () => {}, null, true)}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'paymentStatus',
                  () => {},
                  'Payment Status',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'updatedAt',
                  () => {},
                  'Updated At',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'assignee',
                  () => {},
                  'Assignee',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  buyForMeServiceDetails,
                  'amountToPay',
                  () => {},
                  'Amount To Pay For The Order',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  buyForMeServiceDetails,
                  'specialInstructions',
                  () => {},
                  'Special Instructions',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  buyForMeServiceDetails,
                  'category',
                  () => {},
                  'Order Category',
                  true,
                )}
              </Col>
            </Row>
            <Row hidden={!buyForMeServiceDetails.categoryOtherDescription}>
              <Col>
                {DetailsStdComponent(
                  buyForMeServiceDetails,
                  'categoryOtherDescription',
                  () => {},
                  'Category Other Description',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  buyForMeServiceDetails,
                  'moreDetails',
                  () => {},
                  'More Details',
                  true,
                )}
              </Col>
            </Row>
            <Row hidden={!buyForMeServiceDetails.requiresTransportation}>
              <Col>
                {DetailsStdComponent(
                  buyForMeServiceDetails,
                  'requiresTransportation',
                  () => {},
                  'The Order Requires Transportation',
                  true,
                )}
              </Col>
            </Row>
            <Row hidden={!buyForMeServiceDetails.orderCreatedByUser}>
              <Col>
                {DetailsStdComponent(
                  buyForMeServiceDetails,
                  'orderCreatedByUser',
                  () => {},
                  'The User Created The Order',
                  true,
                )}
              </Col>
            </Row>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey="1">
          <AccordionHeader>Location Details</AccordionHeader>
          <AccordionBody>
            <Card body> Pickup Details </Card>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'preferredPickupTimes',
                  () => {},
                  null,
                  true,
                )}
              </Col>
            </Row>
            <Card body className="mt-4">
              Pickup Location Details
            </Card>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'pickUpAddress',
                  () => {},
                  'Address',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}),
                  'residentDetails',
                  () => {},
                  'Name',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}),
                  'addressPhone',
                  () => {},
                  'Mobile',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}),
                  'addressDescription',
                  () => {},
                  'Description',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}) || {},
                  'floor',
                  () => {},
                  'Floor',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}) || {},
                  'apartment',
                  () => {},
                  'Apartment No.',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}) || {},
                  'doorCode',
                  () => {},
                  'Door Code',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 0, {}) || {},
                  'hasElevator',
                  () => {},
                  'Has Elevator',
                  true,
                )}
              </Col>
            </Row>
            <Card body className="mt-4">
              DropOff Location Details
            </Card>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'dropOffAddress',
                  () => {},
                  'Address',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}),
                  'residentDetails',
                  () => {},
                  'Name',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}),
                  'addressPhone',
                  () => {},
                  'Mobile',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}),
                  'addressDescription',
                  () => {},
                  'Description',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}) || {},
                  'floor',
                  () => {},
                  'Floor',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}) || {},
                  'apartment',
                  () => {},
                  'Apartment No.',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}) || {},
                  'doorCode',
                  () => {},
                  'Door Code',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  arrEl(order.tripDetails, 1, {}) || {},
                  'hasElevator',
                  () => {},
                  'Has Elevator',
                  true,
                )}
              </Col>
            </Row>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey="2">
          <AccordionHeader>Extra Details</AccordionHeader>
          <AccordionBody>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'promoCode',
                  () => {},
                  'Promo Code',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'trackingId',
                  () => {},
                  'Tracking Id',
                  true,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'courierPhoneNumber',
                  () => {},
                  'Courier PhoneNumber',
                  true,
                )}
              </Col>
            </Row>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey="3">
          <AccordionHeader>Issue Invoice</AccordionHeader>
          <AccordionBody>
            <Row>
              <Col>
                {DetailsStdComponent(
                  invoiceDetails,
                  'vehicleCost',
                  setInvoiceDetails,
                  'Vehicle Cost',
                  !buyForMeServiceDetails.requiresTransportation,
                )}
              </Col>
              <Col>
                {DetailsStdComponent(
                  invoiceDetails,
                  'driverCost',
                  setInvoiceDetails,
                  'Driver Cost',
                  !buyForMeServiceDetails.requiresTransportation,
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                {DetailsStdComponent(
                  invoiceDetails,
                  'amountToPay',
                  setInvoiceDetails,
                  'Amount To Pay',
                  false,
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="m-lg-3"
                  variant="primary"
                  disabled={buyForMeServiceDetails.invoiceReady}
                  onClick={() => issueInvoice()}
                >
                  Issue Invoice
                </Button>
              </Col>
              <Col>
                <Button
                  className="m-lg-3"
                  variant="success"
                  disabled={
                    !buyForMeServiceDetails.invoiceReady ||
                    buyForMeServiceDetails.confirmedPayment ||
                    order.paymentMethod === 'PAY_ONLINE'
                  }
                  onClick={() => confirmPayment()}
                >
                  Confirm And Close
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  buyForMeServiceDetails,
                  'estimatedPrice',
                  () => {},
                  'Initial Price',
                  true,
                )}
              </Col>
              <Col>
                {DetailsStdComponent(order, 'currency', () => {}, null, true)}
              </Col>
            </Row>
            <Row>
              <Col>
                {DetailsStdComponent(
                  order,
                  'price',
                  () => {},
                  'Invoice price',
                  true,
                )}
              </Col>
              <Col>
                {DetailsStdComponent(order, 'currency', () => {}, null, true)}
              </Col>
            </Row>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey="4">
          <AccordionHeader>Items</AccordionHeader>
          <AccordionBody>
            <>
              {buyForMeServiceDetails.items.map((item: any, idx1: number) => {
                return (
                  <>
                    <Row key={idx1}>
                      <Col>
                        {DetailsStdComponent(
                          item,
                          'description',
                          () => {},
                          'Item Description',
                          true,
                        )}
                      </Col>
                    </Row>
                    <Row key={idx1}>
                      <Col>
                        {DetailsStdComponent(
                          item,
                          'weightInKg',
                          () => {},
                          'Item Weight',
                          true,
                        )}
                      </Col>
                    </Row>
                    {item.images.map((url: any, idx2: number) => {
                      const srcUrl = `${url}?authorization=${token}`;
                      return (
                        <Row key={idx2}>
                          <Col>
                            <Image
                              key={idx2}
                              className="m-lg-3"
                              rounded
                              src={srcUrl}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                );
              })}
            </>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </NavbarWrapper>
  );
};

export default BuyForMeServiceDetails;
